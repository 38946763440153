// Pagination

.pagination {
  text-align: center;
  line-height: $pagination-btn-size;
  color: $link-color;

  &__container {
    position: relative;
    display: inline-block;

    @include media-breakpoint-down(xs) {
      width: 100%;
      padding-top: 3em;
    }
  }

  &__prev,
  &__next {
    position: absolute;
    top: 0;
    display: inline-block;

    @include media-breakpoint-up(sm) {
      position: static;
    }
  }

  &__prev {
    left: 0.5em;

    @include media-breakpoint-up(sm) {
      margin-right: $size-8;
    }
  }

  &__next {
    right: 0.5em;

    @include media-breakpoint-up(sm) {
      margin-left: $size-8;
    }
  }

  &__page {
    display: inline-block;
    min-width: $pagination-btn-size;
    height: $pagination-btn-size;
    text-decoration: none;
    border-radius: 50%;
    transition: $btn-transition;

    &:hover,
    &:focus {
      background-color: $gray-300;
    }

    &--active {
      font-weight: $font-weight-normal;
      color: #fff;
      background-color: $link-color !important;
    }
  }
}
