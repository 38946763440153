@mixin carousel-grid {
  display: grid;
  grid-template-columns: repeat(6, calc((100% / 6 - 7px)));
  gap: 8px 8px;
  grid-template-areas:
    "main main main main small-first small-first"
    "main main main main small-second small-second";
}

@mixin carousel-grid-item-first {
  grid-area: main;
  height: 100%;
  border-radius: $border-radius-lg 0 0 $border-radius-lg;
}

@mixin carousel-grid-item-second {
  grid-area: small-first;
  border-radius: 0 $border-radius-lg 0 0;
}

@mixin carousel-grid-item-thirth {
  grid-area: small-second;
  border-radius: 0 0 $border-radius-lg 0;
}

.profile-carousel {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  &:hover {
    cursor: pointer;
  }

  &#{&}--force-mobile {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    .profile-carousel__description {
      display: none;
    }

    .profile-carousel__item {
      width: 80%;

      @include media-breakpoint-down(sm) {
        height: 30vw;
      }
    }
  }

  &#{&}--force-desktop {
    @include carousel-grid;

    .profile-carousel__description {
      display: none;
    }

    .profile-carousel__item {
      padding-top: 56.25%;
      width: 100%;
      height: 100%;
      margin: 0;

      &:first-child {
        @include carousel-grid-item-first;
      }

      &:nth-child(2) {
        @include carousel-grid-item-second;
      }

      &:nth-child(3) {
        @include carousel-grid-item-thirth;
      }
    }
  }

  @include media-breakpoint-up(md) {
    @include carousel-grid;
  }

  &__show-more {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: $font-size-xl;
  }

  &__placeholder-letter {
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    position: absolute;
    top: $size-5;
    left: $size-6;
  }

  &__item {
    position: relative;
    flex-shrink: 0;
    width: 80vw;
    height: 55vw;
    margin: 0 $size-4;
    border-radius: $border-radius-lg;
    background-color: $gray-900;
    overflow: hidden;

    &:not(&:first-child) {
      .profile-carousel__play-button {
        width: 32px;
        height: 32px;
      }
    }

    &--dark img {
      opacity: 0.3;
    }

    &--disabled {
      pointer-events: none;
      cursor: default;
    }

    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.25s;

      &:hover {
        transform: scale(1.05);
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: 56.25%;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    &:first-child {
      @include media-breakpoint-up(md) {
        @include carousel-grid-item-first;
      }
    }

    &:nth-child(2) {
      @include media-breakpoint-up(md) {
        @include carousel-grid-item-second;
      }
    }

    &:nth-child(3) {
      @include media-breakpoint-up(md) {
        @include carousel-grid-item-thirth;
      }
    }
  }

  &__description {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: $size-9 $size-6 $size-6 $size-6;
    color: $white;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $black 100%);
  }

  &__play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    width: 32px;
    height: 32px;
    margin-bottom: $size-6;
    border-radius: 100%;
    color: $gray-400;
    background-color: $white;

    @include media-breakpoint-up(md) {
      width: 48px;
      height: 48px;
    }

    &--force-small {
      width: 32px;
      height: 32px;
      margin-bottom: 0;
    }

    &:hover {
      color: $secondary;
    }
  }
}
