// Quote

.quote {
  display: flex;
  padding: $size-6;
  background-color: $yellow-light;
  border: 1px solid $gray-300;
  border-radius: $border-radius;

  @include media-breakpoint-up(md) {
    padding-left: $size-7;
    padding-right: $size-7;
  }

  &__aside {
    margin-right: $size-6;

    @include media-breakpoint-up(md) {
      margin-right: $size-7;
    }
  }

  &__body {
    margin-bottom: 0;
    white-space: pre-line;
  }
}
