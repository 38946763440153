// Comment

.comment {
  position: relative;

  & + & {
    margin-top: $size-8;
    padding-top: $size-8;
    border-top: 1px solid $gray-200;

    @include media-breakpoint-up(sm) {
      margin-top: $size-7;
      padding-top: $size-9;
    }
  }

  &--confirmed + &--unconfirmed {
    border-top: 2px solid $brand-pink;
  }

  &__profile-logo-container {
    min-width: 65px;
  }

  &__profile-logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    padding: 5px;
    border: $gray-500 solid 1px;
    border-radius: 5px;
    margin-bottom: $size-7;
    background-color: $white;
  }

  &__job-description-link {
    margin-bottom: $size-3;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__cta-button {
    @include media-breakpoint-down(md) {
      margin-top: $size-8;
    }
  }

  &__text {
    white-space: pre-line;
  }
}
