// Sections

.section {
  &__background {
    background-color: $gray-150;

    &--report {
      background-color: rgba($brand-blue, 0.07);
    }

    &--gradient {
      color: $white !important;
      background: rgb(63, 94, 251);
      background: linear-gradient(106.1deg, #ae84c2 1.88%, #9e8bc1 47.88%, #8491ca 94.6%);
    }

    &--feedback-bar {
      background-color: $violet-light;
    }
  }

  &--loading {
    opacity: 0.3;
  }

  &__container {
    padding-top: $size-8;
    padding-bottom: $size-8;

    @include media-breakpoint-up(sm) {
      padding-top: $size-9;
      padding-bottom: $size-9;
    }
  }

  &[id] {
    &::before {
      content: '';
      height: 4rem;
      display: block;
      margin-top: -4rem;
      visibility: hidden;

      @include media-breakpoint-up(sm) {
        height: 1.5rem;
        margin-top: -1.5rem;
      }
    }
  }
}
