// Navigation

.nav-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 4px;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-inline: 24px;
  }

  @include media-breakpoint-up(lg) {
    padding-right: 40px;
    padding-left: 32px;
  }

  &.questionnaire-nav-wrapper {
    display: block;
    padding-inline: 32px;
  }

  .nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * {
      margin-right: 12px;

      @include media-breakpoint-up(sm) {
        margin-right: 24px;
      }
    }

    @include media-breakpoint-up(md) {
      flex-grow: 1;
    }

    .redesigned-button {
      padding: 8px 16px;
      font-weight: 500;
      font-size: 0.875rem;
      text-align: center;
      text-decoration: none;
      color: #fff;
      background-color: #3f96e0;
      border-color: #3f96e0;
      border-radius: 4px;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        color: #fff;
        background-color: #3174ad;
        border-color: #3174ad;
      }
    }

    a {
      font-weight: 300;
      color: #343434;

      &:hover {
        text-decoration: none;
        color: #3f96e0;
      }

      &:active {
        color: #22527a;
      }
    }

    .redesigned-badge {
      display: inline-block;
      padding-inline: 6px;
      margin-left: 8px;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.5;
      color: #fff;
      background-color: #3f96e0;
      border-radius: 1.875em;
    }

    .user-placeholder {
      width: 2.5rem;
      height: 2.5rem;
    }

    .redesigned-dropdown {
      min-width: 13rem;
      padding: 24px 32px 32px 24px;
      border: none;
      border-radius: 6px;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.14);

      .user-email {
        color: #90a2a7;
      }

      a {
        cursor: pointer;
      }

      > *:not(:last-child) {
        display: inline-block;
        width: 100%;
        margin-bottom: 16px;
        font-weight: 400;
      }
    }

    .search-wrapper {
      margin-left: 24px;
      margin-right: 19px;

      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-end;
        max-width: 10rem;
        margin-right: 24px;
      }
    }

    .login-link {
      margin-right: 0;
      color: #3f96e0;

      @include media-breakpoint-up(sm) {
        margin-right: 24px;
      }

      &:hover,
      &:focus {
        color: #3174ad;
      }
    }

    .redesigned-review-link {
      white-space: nowrap;
    }

    .js-logout-link {
      font-weight: 400;
      color: #3f96e0;

      &:hover,
      &:focus {
        color: #3f96e0;
      }
    }

    #hamburger-menu {
      .dropdown-menu {
        width: 100%;
        min-width: 220px;
        padding: 16px 8px 20px;
        margin-top: 17px;
        text-align: left;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.14);

        a {
          display: block;
          padding: 8px 12px;
          margin-bottom: 0;
          font-weight: 400;

          &:hover,
          &:focus {
            color: #343434;
            background-color: #f2f2f2;
          }

          &.login-link {
            margin-right: 0;
          }
        }

        hr {
          display: block;
          margin: 8px -9px 8px -8px;
          border: 1px solid #e9e9e9;
        }

        .user-email {
          padding: 8px 12px;
          color: #90a2a7;
        }
      }

      .redesigned-hamburger {
        position: relative;
        width: 22px;
        height: 14px;
        padding: 5px;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;

        span {
          position: absolute;
          left: 0;
          display: block;
          width: 100%;
          height: 2px;
          background: #343434;
          border-radius: 4px;
          opacity: 1;
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2),
          &:nth-child(3) {
            top: 6px;
          }

          &:nth-child(4) {
            top: 12px;
          }
        }
      }

      .dropdown.show .redesigned-hamburger span {
        &:nth-child(1) {
          top: 18px;
          left: 50%;
          width: 0;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 18px;
          left: 50%;
          width: 0;
        }
      }
    }
  }
}
