#floating-action-button {
  z-index: 999;
  bottom: 50px;
  right: 10px;

  @include media-breakpoint-up(md) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .wide-button {
    @include media-breakpoint-up(md) {
      width: 400px;
    }
  }

  #close-floating-action-button {
    @include media-breakpoint-up(md) {
      position: absolute;
      right: 10px;
    }
  }

  .arrow-down-icon {
    stroke-width: 0.9px;
  }
}

.btn-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
