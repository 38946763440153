.homepage-hero {
  position: relative;
  background-image: url(/images/homepageHero/hero-xs.jpeg);
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 55%;
  }

  @include media-breakpoint-up(sm) {
    background-image: url(/images/homepageHero/hero-sm.jpeg);
  }

  @include media-breakpoint-up(md) {
    background-image: url(/images/homepageHero/hero-md.jpeg);
  }

  @include media-breakpoint-up(lg) {
    background-image: url(/images/homepageHero/hero-lg.jpeg);
  }

  @include media-breakpoint-up(lg) {
    background-image: url(/images/homepageHero/hero-lg.jpeg);
  }

  @include media-breakpoint-up(xl) {
    background-image: url(/images/homepageHero/hero-xl.jpeg);
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__button {
    flex-basis: 100%;
    max-width: 200px;
    width: 100%;
  }

  &__title {
    font-family: $font-family-sans-serif-secondary;
    font-weight: 700;
    font-size: $display4-size;
    color: $white;
    line-height: $line-height-sm;

    @include media-breakpoint-up(md) {
      font-size: $display3-size;
    }
  }

  &__subtitle {
    @extend .h2;

    @include media-breakpoint-up(md) {
      @include font-size($h1-font-size);
    }
  }
}
