// Tiles

$tiles-gap: 3rem;
$tiles-gap-sm: 1.5rem;
$tiles-breakpoint: md;

.tiles {
  overflow: hidden;

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: -($tiles-gap-sm * 0.5);

    @include media-breakpoint-up($tiles-breakpoint) {
      margin: -($tiles-gap * 0.5);
    }
  }

  &__item {
    flex: 0 0 auto;
    width: 50%;
    margin-bottom: 1rem;
    padding: ($tiles-gap-sm * 0.5);

    @include media-breakpoint-up($tiles-breakpoint) {
      padding: ($tiles-gap * 0.5);
    }
  }
}

.tile {
  &__container {
    color: $body-color;
    text-decoration: none;

    @include hover-focus() {
      color: $body-color;
    }
  }

  &__header {
    position: relative;
    margin-bottom: $size-6;
    border-radius: $border-radius-lg;
    overflow: hidden;

    &:hover {
      .tile {
        &__image {
          transform: scale3d(1.05, 1.05, 1.05) rotate(0.1deg);
          transition-duration: 3s;

          &::after {
            opacity: 0.3;
            transition-duration: 2.5s;
          }
        }
      }
    }

    &-title {
      position: absolute;
      z-index: 1;
      top: 45%;
      left: 0;
      right: 0;
      padding: 0.5rem;
      font-size: 5vw;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.1;
      color: #fff;
      transform: translate3d(0, -50%, 0);
      text-shadow: 0 0 4rem rgba($brand-blue, 0.2), 0 0 4rem rgba(#000, 0.6);
      pointer-events: none;

      @include media-breakpoint-up($tiles-breakpoint) {
        font-size: 2.5rem;
      }
    }
  }

  &__body {
    color: currentColor;
  }

  &__image {
    height: 0;
    padding-bottom: 67.5%;
    background-color: $gray-300;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transform-origin: 25% 75%;
    transition: transform 1s ease;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: darken($brand-blue, 30%);
      transition: opacity 1s ease;
    }

    @include media-breakpoint-up($tiles-breakpoint) {
      padding-bottom: 42.5%;
    }

    > img {
      display: none;
    }
  }

  &__title {
    margin-bottom: 0.25rem;
    font-size: 1.125rem;
    text-transform: none;
    color: $body-color;
  }

  &__subtitle {
    margin-bottom: 0;
  }

  &__more {
    margin-bottom: 0;
    display: inline-block;
    color: $link-color;

    @include hover() {
      text-decoration: underline;
    }
  }

  &--sm {
    @include media-breakpoint-up($tiles-breakpoint) {
      width: 33.33%;
    }

    .tile {
      &__header {
        &-title {
          display: none;
        }
      }

      &__image {
        padding-bottom: 52.5%;
      }
    }
  }

  &--xs {
    @include media-breakpoint-up($tiles-breakpoint) {
      width: 25%;
    }

    .tile {
      &__header {
        &-title {
          display: none;
        }
      }

      &__image {
        padding-bottom: 52.5%;
      }
    }
  }

  &--lg {
    width: 100%;

    .tile {
      &__header {
        &-title {
          font-size: 12vw;

          @include media-breakpoint-up($tiles-breakpoint) {
            font-size: 5.75rem;
            text-shadow: 0 0 4rem rgba($brand-blue, 0.2), 0 0 10rem rgba(#000, 0.6);
          }
        }
      }

      &__image {
        padding-bottom: 42.5%;
      }
    }
  }
}
