// Search

.company-search-form {
  .search {
    height: 3.5rem;
  }

  input {
    height: inherit;
  }
}

.search {
  position: relative;
  width: 100%;
  height: 40px;
  color: #90a2a7;
  transition: max-width 0.15s ease-in-out;

  & > svg {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
  }

  &--open {
    .search {
      &__input {
        @include media-breakpoint-down(sm) {
          width: 12rem;
          padding-right: 44px;
        }
      }
    }
  }

  &__action {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: inherit;
    height: inherit;
    padding: 8px 8px 8px 8px;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out;
    transition: all 0.3s ease;
    text-overflow: ellipsis;

    @include media-breakpoint-up(md) {
      position: static;
      width: 100%;
      padding-left: 40px;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    @include media-breakpoint-up(md) {
      font-size: 0.875rem;
    }

    &:active,
    &:focus,
    &:hover {
      border-color: #3f96e0;
    }

    &::placeholder {
      opacity: 1;
      color: #90a2a7;
    }
  }

  .icon {
    width: 24px !important;
    height: 24px !important;
  }
}

.search-dropdown {
  &__item.active &__company-name {
    color: #fff;
  }

  &__rating-count {
    white-space: nowrap;
    color: #90a2a7;
  }

  .dropdown {
    &-menu {
      width: 15rem;
      padding: 16px 8px 24px;
      margin-top: 0.25rem;
      border: none;
      border-radius: 6px;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.14);

      @include media-breakpoint-up(sm) {
        width: 23rem;
      }

      .dropdown-header-redesigned {
        padding: 8px 12px;
        margin-bottom: 0;
        font-size: inherit;
        font-weight: inherit;
        color: #90a2a7;
      }

      &--in-header {
        right: -3rem;

        @include media-breakpoint-up(sm) {
          right: 0;
        }

        @include media-breakpoint-up(md) {
          left: 0;
        }
      }
    }

    &-item {
      white-space: normal;
      padding: 8px 12px;

      @include hover-focus {
        color: #343434;
        background-color: #f2f2f2;
      }
    }
  }
}
