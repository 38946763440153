.lb-data .lb-close {
  position: absolute;
  top: -2.5rem;
  right: 2rem;
}

.lb-outerContainer {
  background-color: $gray-900;
}

.lb-data .lb-caption {
  font-size: $font-size-base;
  font-weight: 400;

  span {
    display: block;
    margin-top: $size-5;
    margin-bottom: $size-6;
    font-weight: bold;
  }

  margin-bottom: $size-6;
}
