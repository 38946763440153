.heart-picker {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  max-width: 25rem;

  &__heart {
    color: #e6e6e6;
    padding-left: $size-4;
    padding-right: $size-4;
    cursor: pointer;

    &--1.active,
    &--1:hover,
    .heart-picker:hover &--1 {
      color: #537fed;
    }

    &--2.active,
    &--2:hover,
    .heart-picker:hover &--2 {
      color: #7a7adb;
    }

    &--3.active,
    &--3:hover,
    .heart-picker:hover &--3 {
      color: #a167c3;
    }

    &--4.active,
    &--4:hover,
    .heart-picker:hover &--4 {
      color: #ce5bab;
    }

    &--5.active,
    &--5:hover,
    .heart-picker:hover &--5 {
      color: #e9539f;
    }
  }

  &:hover &__heart:hover ~ &__heart {
    color: #e6e6e6;
  }

  &__icon {
    width: 100%;
    height: 100%;
  }
}
