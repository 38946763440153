.profile-visual-content-detail {
  &__image-box {
    position: relative;
    padding-top: 56.25%;
    background-color: $gray-100;
    border-radius: $border-radius-md;
    overflow: hidden;
  }

  &__header {
    position: sticky;
    z-index: 2;
    top: 0;
    display: flex;
    justify-content: flex-end;
    padding-top: $size-6;
    padding-bottom: $size-6;
    background-color: $white;
  }

  &__image {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
  }

  &__video {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  &__wallpaper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > div {
      width: 100%;
      height: 100%;
      filter: blur(65px);
    }

    img {
      width: 100%;
    }
  }

  &__indicators-container {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: $size-6;
    display: flex;
    transform: translateX(-50%);
  }

  &__indicator {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin: 0 $size-3;
    background-color: $white;
    opacity: 0.4;

    &--active {
      opacity: 1;
    }
  }

  &__user-image {
    width: 48px;
    height: 48px;
    margin-right: $size-5;
    flex-shrink: 0;
  }

  &__navigation-button {
    border: none;
    color: $white;
    background-color: transparent;
    pointer-events: all;
    padding: $size-7 $size-5;

    &:hover {
      svg {
        background-color: $secondary;
      }
    }

    &:first-child {
      transform: rotate(180deg);
    }

    svg {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: $gray-800;
      opacity: 0.8;
    }
  }

  &__close {
    width: 32px;
    height: 32px;
    border: none;
    background: url(/images/common/cross-in-circle.svg) no-repeat 0 57%;
    background-size: 32px 32px;

    &:hover {
      background: url(/images/common/button_cross.svg) no-repeat 0 57%;
      background-size: 32px 32px;
    }
  }

  &__control-box {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    &--video {
      pointer-events: none;
    }
  }
}
