.alert-wrapper-fixed {
  position: fixed;
  z-index: 9999;
  top: $size-10;
  left: 50%;
  min-width: 300px;
  transform: translateX(-50%);

  .alert {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
