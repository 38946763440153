// Dropdown

.dropdown {
  &-menu {
    //margin-top: -1px;
    font-size: $font-size-sm;
  }

  &-item {
    text-decoration: none;

    &:hover,
    &:focus,
    &.active,
    &:active {
      color: #fff;
      background-color: $link-color;
    }
  }

  &-header {
    font-size: $font-size-md;
    color: $headings-color;
  }
}

.dropup {
  .dropdown {
    &-menu {
      //margin-bottom: -1px;
      z-index: $zindex-dropdown;
    }
  }
}
