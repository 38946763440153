.profile-image-edit {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;

    img {
      max-height: 600px;
    }
  }

  &__crop-box {
    border: 1px dashed $gray-300;

    &--active-drop {
      border: 1px solid $brand-blue-light;
      box-shadow: 0 0 0 0.2rem rgba(60, 65, 160, 0.25);
    }
  }
}
