// Selectize

.selectize {
  &-input {
    padding: $input-btn-padding-y $input-btn-padding-x;
    font-size: $font-size-sm;
    line-height: $line-height-base;
    border-color: $input-border-color;
    border-radius: $input-border-radius;
    box-shadow: none !important;
    vertical-align: bottom;

    input {
      position: static !important;
      font-size: $font-size-sm;
    }
  }

  &-control {
    &.multi {
      .selectize-input {
        &.has-items {
          padding: 0.25rem 0.25rem 0.0625rem;
        }

        > div,
        > div.active {
          padding: $size-3 $size-5;
          color: $gray-700;
          background-color: $gray-200;
          border-radius: $border-radius-sm;

          &:focus,
          &:hover {
            color: $brand-blue;
          }
        }
      }
    }

    &.plugin-remove_button {
      [data-value] {
        padding-right: 2rem !important;

        .remove {
          width: 20px;
          margin-right: 0.25rem;
          font-size: $font-size-base;
          font-weight: $font-weight-normal;
          color: transparent;
          border: 0;
          background: url(/images/common/cross-in-circle.svg) no-repeat 0 57%;
          background-size: 20px 20px;

          &:focus,
          &:hover {
            background: url(/images/common/button_cross.svg) no-repeat 0 57%;
            background-size: 20px 20px;
          }
        }
      }
    }
  }

  &-dropdown {
    margin-top: 2px;
    padding: $dropdown-padding-y 0;
    font-size: $font-size-sm;
    line-height: $line-height-base;
    border: 1px solid $dropdown-border-color;
    border-radius: $dropdown-border-radius;
    box-shadow: none;

    .option {
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;

      &[data-selectable] {
        cursor: pointer;

        .highlight {
          background-color: rgba($brand-pink-light, 0.5);
        }
      }
    }

    .active {
      color: #fff;
      background-color: $link-color;
    }
  }
}

.filter-add-placeholder .selectize-input.has-items:not(.full)::after {
  padding: 0.25rem 0;
  display: inline-block;
  content: "+ p\159idat";
  color: $link-color;
}

select.filter-add-placeholder {
  // hide the default select in order to avoid flickering before Selectize.js is rendered
  display: none;
}
