.multi-value-select {
  &__item {
    background-color: $gray-200 !important;
  }

  &__placeholder {
    font-size: $font-size-sm;
  }

  &__control {
    border-color: $gray-300 !important;
  }

  &__option {
    &:focus,
    &:active,
    &:hover {
      color: $white !important;
      background-color: $primary !important;
    }
  }

  &__add {
    color: $brand-blue;
    font-size: $font-size-sm;
  }

  &__remove {
    width: 20px;
    margin-right: 0.25rem;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    color: transparent;
    border: 0;
    background: url(/images/common/cross-in-circle.svg) no-repeat center center;
    background-size: 20px 20px;

    &:focus,
    &:hover {
      background: url(/images/common/button_cross.svg) no-repeat center center;
      background-size: 20px 20px;
    }
  }
}
