// Profile overview

.overview {
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-top: 1px solid $gray-400;
  border-bottom: 1px solid $gray-400;
  box-shadow: 0 0 5rem rgba($brand-blue, 0.2);

  &__tabs-container {
    height: $size-9;

    &--shadow {
      box-shadow: 0 4px 2px -2px rgba($brand-blue, 0.1);
    }
  }

  &__tabs {
    position: relative;
    background: $gray-150;
    box-shadow: 0 4px 2px -2px rgba($brand-blue, 0.1);

    &--fixed {
      position: fixed;
      top: 0;
      z-index: $zindex-sticky;
    }
  }
}
