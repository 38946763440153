// Avatar

.avatar {
  width: 100%;
  height: auto;
  max-width: 5rem;
  max-height: 5rem;
  display: block;
  background-color: #fff;
  border: 1px solid $gray-300;
  border-radius: 50%;
  overflow: hidden;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: top;
  }

  &--sm {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--lg {
    @include media-breakpoint-up(md) {
      width: 9rem;
      height: 9rem;
    }
  }

  &--muted {
    .avatar {
      &__image {
        opacity: 0.5;
      }
    }

    @supports (filter: grayscale(1)) {
      .avatar {
        &__image {
          opacity: 0.75;
          filter: grayscale(1);
        }
      }
    }
  }
}
