// Shadows

.shadow-arc {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1rem;
    z-index: -1;
    width: 50%;
    height: 2rem;
    box-shadow: 0 0.5rem 1.5rem rgba($brand-blue, 0.2);
    transform: rotate(-4.5deg);
    transform-origin: left bottom;
  }

  &::after {
    right: 1rem;
    left: auto;
    transform: rotate(4.5deg);
    transform-origin: right bottom;
  }

  &--lg {
    &::before,
    &::after {
      box-shadow: 0 1.25rem 2rem rgba($brand-blue, 0.2);
    }
  }
}

.text-shadow {
  text-shadow: 0 0 0.5rem rgba($black, 0.5);
}
