.badge {
  // We need to have transparent border by default so that the sizes
  // of badges with and without outline are the same.
  border: 1px solid transparent;
}

a.badge-gray-200:hover {
  color: $brand-blue !important;
  background-color: $gray-200 !important;
}
