.culture-landing-page {
  font-size: $font-size-lg;

  h2,
  h3 {
    font-family: $font-family-sans-serif-secondary;
  }

  &__sticky-header {
    display: none;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $white;
    box-shadow: $box-shadow-sm;
  }

  &__company {
    display: flex;
    margin-bottom: $size-9;

    @include media-breakpoint-up(md) {
      width: $size-7;
    }

    img {
      width: 90px;

      @include media-breakpoint-up(md) {
        width: 110px;
      }
    }
  }

  &__team-member {
    display: flex;
    align-items: center;
    margin-bottom: $size-7;
    padding: $size-7;
    border-radius: $border-radius-lg;
    background-color: $white;

    @include media-breakpoint-up(md) {
      margin: 0 auto $size-7 auto;
      width: 620px;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      padding: 0;
      margin: 0;
      width: auto;
      height: 100%;
    }
  }

  &__team-member-image {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    border-radius: 100%;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      border-radius: 0;
      min-height: 190px;
      max-height: 190px;
      min-width: 100%;
      max-width: 100%;
      border-top-left-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;

      img {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  &__inspirational-leader {
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin-bottom: $size-10;
    border-radius: $border-radius-lg;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      min-width: 400px;
      max-width: 460px;
    }

    @include media-breakpoint-up(md) {
      position: relative;
      top: -50px;
    }
  }

  &__inspiration-leader-title-box {
    @include media-breakpoint-up(md) {
      max-width: 210px;
    }

    @include media-breakpoint-up(lg) {
      max-width: none;
    }
  }

  &__inspirational-leader-avatar {
    flex-shrink: 0;
    max-width: 5rem;
    max-height: 5rem;
    border-radius: 50%;
  }

  &__inspirational-leader-image {
    @include media-breakpoint-up(md) {
      height: 340px;
      overflow: hidden;
      border-radius: $border-radius-lg;

      img {
        width: 100%;
      }
    }
  }

  &__inspirational-leader-content-image {
    width: 100%;
    height: 190px;
    border-radius: $border-radius-lg;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &__contact-avatar {
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
  }
}
