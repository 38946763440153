.question {
  &__label {
    color: $black;
    margin-bottom: $size-7;
  }

  &__group-label {
    margin-bottom: $size-4;
  }

  &__help-text {
    color: $text-muted;
    margin-top: $size-7;
    margin-bottom: $size-5;
  }
}
