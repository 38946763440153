.profile-list {
  @include list-unstyled;
}

.profile-list-item {
  padding-top: $size-7;
  padding-bottom: $size-7;
  border-bottom: $border-width solid $border-color;

  &:last-child {
    border-bottom: none;
  }

  &__title {
    font-size: $h4-font-size;
    font-weight: $headings-font-weight;
  }

  &__number-of-reviews {
    color: $secondary;
    font-size: $font-size-sm;
  }
}
