// Webinars

.webinars {
  &__event {
    margin-bottom: $size-7;
    padding: $size-7 $size-8;
    border: 1px solid $gray-300;
    border-radius: $border-radius-lg;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
