// Popover

.popover {
  box-shadow: 0 0 1rem rgba($brand-blue, 0.1);

  &-header {
    border-top-left-radius: $popover-border-radius;
    border-top-right-radius: $popover-border-radius;
  }
}
