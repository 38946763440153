.pricing {
  &__monthly-price {
    color: $brand-pink;
    font-size: x-large;
    font-weight: bold;
  }

  &__monthly-price-number {
    font-size: xx-large;
  }

  &__badge {
    color: $white;
    background-color: #727bb6;
    font-size: small;
    margin: 0;
  }
}
