// Cards

.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: $size-7;
  text-decoration: none;
  color: $body-color;
  background-color: #fff;
  border: 1px solid $gray-300;
  border-radius: $border-radius;

  &--include-ie11-fix {
    @include media-breakpoint-up(sm) {
      height: 1px; // IE11 flex-box fix
      min-height: 35rem;
    }
  }

  &:hover,
  &:focus {
    color: currentColor;
  }

  &__unstyled-link {
    text-decoration: none;
    color: $body-color;

    &:hover,
    &:focus {
      color: currentColor;
    }
  }

  &__body {
    width: 100%;

    &--premium {
      margin-top: $size-8;
    }
  }

  &__header {
    min-height: 5rem;

    &--premium {
      margin-bottom: $size-5;
      min-height: 5rem;
    }
  }

  &__logo {
    width: 60px;
    min-width: 60px;
    height: 60px;
    object-fit: contain;
    padding: 5px;
    border: $gray-500 solid 1px;
    border-radius: 5px;
    margin-left: $size-6;
  }

  &__content {
    min-height: 9rem;

    &--tall {
      min-height: 12rem;
    }
  }

  &__image-container {
    height: $size-10;
    overflow: hidden;
    margin-left: -$size-7;
    margin-right: -$size-7;
    margin-bottom: -$size-7;
  }

  &__image {
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__job-opportunities {
    min-height: 9.5rem;
    margin-bottom: $size-7;
  }

  &__job-opportunities-list {
    min-height: 5rem;
    margin-bottom: $size-7;
  }

  &__report-value {
    font-size: $h1-font-size;
    font-weight: $font-weight-bold;
    color: $brand-blue;

    &--small {
      font-size: $font-size-base;
    }
  }
}
