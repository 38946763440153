.ReactModal {
  &__Overlay {
    -webkit-perspective: 600;
    perspective: 600;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;

    &--after-open {
      opacity: 1;
      transition: opacity 150ms ease-out;
    }

    &--before-close {
      opacity: 0;
    }
  }

  &__Content {
    -webkit-transform: scale(0.5) rotateX(-30deg);
    transform: scale(0.5) rotateX(-30deg);

    &--after-open {
      -webkit-transform: scale(1) rotateX(0deg);
      transform: scale(1) rotateX(0deg);
      transition: all 150ms ease-in;
    }

    &--before-close {
      -webkit-transform: scale(0.5) rotateX(30deg);
      transform: scale(0.5) rotateX(30deg);
      transition: all 150ms ease-in;
    }

    .modal-dialog {
      border: none;
      background-color: transparent;
    }
  }
}
