.culture-header {
  height: 80vh;
  font-size: $font-size-lg;
  background-image:
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(/images/cultureLandingPage/culture-header-background.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;

  h1 {
    font-family: $font-family-sans-serif-secondary;
    font-size: $display3-size;
    color: $white;
  }

  @include media-breakpoint-up(sm) {
    height: 620px;
  }

  @include media-breakpoint-up(md) {
    height: 450px;
  }

  @include media-breakpoint-up(lg) {
    height: 80vh;

    h1 {
      font-size: $display2-size;
    }
  }

  &__button-box {
    position: absolute;
    bottom: $size-5;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-up(sm) {
      position: static;
      transform: none;
    }
  }
}
