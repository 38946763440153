// Brand

.brand {
  margin-right: 24px;

  @include media-breakpoint-up(sm) {
    margin-right: 0;
  }

  &__logo {
    @include media-breakpoint-up(sm) {
      width: 142px;
      height: 22px;
    }

    &--large {
      width: 10rem;
      height: 2rem;
    }

    &--collapsible {
      max-width: 100%;
    }
  }
}
