.profile-image-upload {
  &__file-input {
    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: $input-btn-padding-y;
    }
  }
}

.profile-image-upload__file-input > input {
  display: none;
}

.profile-image-upload__file-input > label {
  @extend .btn, .btn-outline-primary;
}
