// Blockquote

.blockquote {
  font-size: $font-size-base;
}

.blockquote-footer {
  font-size: inherit;

  &--no-dash::before {
    content: none;
  }

  &__author {
    display: flex;
    align-items: center;
  }
}
