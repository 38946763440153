// Almacareer footer

// Do not let Bootstrap overwrite color of headings in Alma footer
.almc-footer h1,
.almc-footer h2,
.almc-footer h3,
.almc-footer h4,
.almc-footer h5,
.almc-footer h6, {
  color: inherit;
}
