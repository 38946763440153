.profile-images-section {
  &__header {
    margin-bottom: -$size-9;
  }

  &__item {
    a {
      &:hover {
        div > svg {
          color: $secondary;
        }
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info-box {
    border-radius: $border-radius-sm;

    @include media-breakpoint-up(lg) {
      white-space: nowrap;
    }

    &--published {
      background-color: $yellow-light;
    }

    &--unpublished {
      background-color: $violet-light;
    }

    span {
      color: $brand-blue-light;
    }
  }

  &__image-box {
    position: relative;
    border: 1px dashed $gray-300;
    border-radius: $border-radius-sm;
    padding-top: 56.25%;
    overflow: hidden;
  }

  &__show_hide_button {
    width: 100%;
    margin-bottom: $size-6;

    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 0;
      margin-bottom: 0;
    }
  }

  &__title-placeholder-title {
    background-color: $gray-200;
    width: 75%;
    height: $font-size-base;
  }

  &__title-placeholder-text {
    background-color: $gray-200;
    width: 100%;
    height: $font-size-xs;

    &:last-child {
      width: 85%;
    }
  }

  &__edit {
    position: absolute;
    bottom: $size-5;
    right: $size-5;
  }

  &__play-video {
    position: absolute;
    bottom: $size-5;
    left: $size-5;
  }

  &__add-image {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $gray-500;
    transform: translate((-50%, -50%));
  }

  &__description {
    font-size: $font-size-sm;
    color: $gray-600;
    width: 100%;
    word-break: break-all;
  }
}
