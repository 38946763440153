.profile-section {
  background-color: $gray-150;
  padding: $size-7;
  border-radius: 0;
  overflow-x: hidden;

  @include media-breakpoint-up(md) {
    border-radius: $size-4;
  }

  &__break {
    border: 0;
    padding-bottom: $size-3;
    background-color: $gray-300;
    margin-top: $size-7;
    margin-bottom: $size-7;
    margin-left: -$size-7;
    margin-right: -$size-7;
  }
}
