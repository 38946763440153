// Tip

.tip {
  & + &,
  // also include tip that is followed by tip nested in some element
  & + * > & {
    margin-top: $size-5;
    padding-top: $size-7;
    border-top: 1px solid $gray-200;
  }
}
