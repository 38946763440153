// Header

.header {
  padding-bottom: 8px;
  font-size: 0.875rem;
  font-family: sans-serif;

  .header-gradient {
    width: 100%;
    height: 4px;
    background-image: linear-gradient(270deg, #c16bea -4.52%, #b46ed3 13.99%, #ffdfb7 66.25%, #fbe1ae 100%);
  }

  .redesigned-container {
    max-width: 1280px;
    margin: auto;
    padding-inline: 16px;

    @include media-breakpoint-up(md) {
      padding-inline: 32px;
    }
  }
}

#job-description-list-filter::before {
  display: none !important;
}
