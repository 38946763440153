// Profile logo

.profile-logo {
  &__image {
    max-width: 89px;
    height: 55px;
    object-fit: contain;

    @include media-breakpoint-up(md) {
      max-width: 144px;
      height: 68px;
    }
  }
}
