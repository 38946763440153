.profile-preview {
  &__logo-placeholder {
    width: 1.75rem;
    padding-top: $size-6;
    padding-bottom: $size-6;
    background-color: $gray-200;
    border-radius: $border-radius-lg;
  }

  &__atmoskop-logo {
    width: 5.5rem;
  }

  &__navigation {
    background-color: $gray-200;
    padding: $size-6 $size-8;
    margin-right: $size-5;
    border-radius: $border-radius-sm;

    &--active {
      background-color: $gray-900;
    }
  }

  &--force-mobile {
    @include media-breakpoint-down(md) {
      max-width: 300px;
    }
  }
}
