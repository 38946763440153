// Buttons

.btn {
  font-size: $font-size-sm;
  text-decoration: none;
  white-space: nowrap;

  &-primary {
    color: #fff;
    background-color: $secondary;
    border-color: $secondary;

    @include hover-focus-active {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }

  &-secondary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;

    @include hover-focus-active {
      color: #fff;
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  &-outline {
    &-primary {
      color: $secondary;
      background-color: #fff;
      border-color: currentColor;

      @include hover-focus-active {
        color: $primary !important;
        background-color: #fff !important;
        border-color: currentColor;
      }
    }

    &-secondary {
      color: $primary;
      background-color: #fff;
      border-color: currentColor;

      @include hover-focus-active {
        color: $secondary !important;
        background-color: #fff !important;
        border-color: currentColor;
      }
    }
  }

  &-light {
    @include hover-focus-active {
      background-color: $gray-100;
    }
  }

  &-square {
    width: $input-height-inner;
    padding-left: 0;
    padding-right: 0;
  }

  &-lg {
    font-size: $font-size-base;
  }

  &-link {
    &--inline {
      padding: 0;
      vertical-align: baseline;
    }
  }

  &--wrap {
    white-space: normal;
  }
}
