// Lists

.dot-list {
  @include list-unstyled;

  margin-bottom: 1rem;
  margin-left: 0.5rem;

  > li {
    position: relative;
    padding-left: 1em;

    &:not(:last-child) {
      margin-bottom: 0.25em;
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0.7em;
      content: '';
      width: 0.25em;
      height: 0.25em;
      display: block;
      background-color: $brand-pink;
      border-radius: 50%;
    }
  }
}

.icon-list {
  @include list-unstyled;

  &__item {
    display: flex;
    align-items: baseline;

    &:not(:last-child) {
      margin-bottom: 0.25em;
    }

    &-icon {
      position: relative;
      top: 0.15em;
      margin-right: 0.6em;
      flex: 0 0 1em;
    }
  }
}

.tick-list {
  @include list-unstyled;

  &__item {
    padding-left: 1.6em;
    background-image: url('/images/list-tick.svg');
    background-repeat: no-repeat;
    background-size: 1em;
    background-position-y: 0.25em;

    &:not(:last-child) {
      margin-bottom: 0.25em;
    }

    &--without-tick {
      background-image: none;
    }

    &--tall {
      &:not(:last-child) {
        margin-bottom: $size-7;
      }
    }
  }
}

// Default Bootstrap style is missing styling for focused state of interactive list-group items
.list-group {
  &-item:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
    outline: 0;
  }
}

dt {
  color: $gray-500;
}

dd {
  color: $gray-600;
}
