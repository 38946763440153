// Atmoskop main
@use '../../node_modules/@lmc-eu/cookie-consent-manager/LmcCookieConsentManager.css';

// fonts
@import 'modules/fonts';

// Variables
//@import 'modules/variables'; // Loaded inside bootstrap.scss

@import 'bootstrap';
@import '../../node_modules/bootstrap-select/dist/css/bootstrap-select.css';
@import '../../node_modules/selectize/dist/css/selectize.css';
@import '../../node_modules/selectize/dist/css/selectize.bootstrap3.css';
@import '../../node_modules/react-modal-video/scss/modal-video.scss';
@import '../../node_modules/lightbox2/dist/css/lightbox.css';
@import '../../node_modules/react-image-crop/dist/ReactCrop.css';

// Modules
@import 'modules/animations';
@import 'modules/mixins';
@import 'modules/layout';
@import 'modules/scaffolding';
@import 'modules/typo';
@import 'modules/icons';
@import 'modules/forms';
@import 'modules/utilities';
@import 'modules/alert';

// Components
@import 'components/almc-footer';
@import 'components/background-cover';
@import 'components/badge';
@import 'components/blockquote';
@import 'components/bottom-bar';
@import 'components/buttons';
@import 'components/call-to-action-panel';
@import 'components/footer';
@import 'components/form-inverted';
@import 'components/heart-picker';
@import 'components/honeypot';
@import 'components/lists';
@import 'components/overlay';
@import 'components/pricing';
@import 'components/profile-list';
@import 'components/profile-logo';
@import 'components/profile-section';
@import 'components/question-slider';
@import 'components/reaction';
@import 'components/tabs';
@import 'components/brand';
@import 'components/header';
@import 'components/header-photo';
@import 'components/nav';
@import 'components/b2b-admin-sidebar';
@import 'components/search';
@import 'components/overview';
@import 'components/quote';
@import 'components/avatar';
@import 'components/progress';
@import 'components/question';
@import 'components/sections';
@import 'components/cards';
@import 'components/bootstrap-select';
@import 'components/dropdown';
@import 'components/pagination';
@import 'components/comment';
@import 'components/tip';
@import 'components/popover';
@import 'components/page-nav';
@import 'components/shadows';
@import 'components/tiles';
@import 'components/brand-promo';
@import 'components/rating';
@import 'components/intro';
@import 'components/frame';
@import 'components/selectize';
@import 'components/recharts-graphs';
@import 'components/webinars';
@import 'components/cookiesBar';
@import 'components/profile-carousel';
@import 'components/lightbox';
@import 'components/profile-gallery';
@import 'components/profile-image-edit';
@import 'components/profile-images-section';
@import 'components/profile-image-upload';
@import 'components/multi-value-select';
@import 'components/profile-preview';
@import 'components/homepage-hero';
@import 'components/profile-header.scss';
@import 'components/culture-header';
@import 'components/culture-landing-page';
@import 'components/profile-visual-content-detail';
@import 'components/cookie-bar.scss';
@import 'components/react-modal';
@import 'components/floating-action-button';

// Export scss variables for use in React components
// stylelint-disable
:export {
  colorBrandBlue: $brand-blue;
  colorBrandBlueLight: $brand-blue-light;
  colorGray200: $gray-200;
  colorGray500: $gray-500;
  colorGray600: $gray-600;
  colorGray700: $gray-700;
  colorWhite: $white;
}
// stylelint-enable
