// Utilities
// Text
.text {
  &-no-transform {
    text-transform: none !important;
  }

  &-clamp-overflow-with-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // Hotfix for issue: https://github.com/twbs/bootstrap/issues/30803
  &-break {
    word-break: break-word !important;
  }

  &-decoration-underline {
    text-decoration: underline;
  }
}

// Font size
.font {
  &-size {
    &-base {
      font-size: $font-size-base !important;
    }

    &-lg {
      font-size: $font-size-lg !important;
    }

    &-md {
      font-size: $font-size-md !important;
    }

    &-sm {
      font-size: $font-size-sm !important;
    }

    &-xs {
      font-size: $font-size-xs !important;
    }
  }

  &-weight {
    &-medium {
      font-weight: $font-weight-medium;
    }
  }
}

// Line height
.line-height {
  &-base {
    line-height: $line-height-base;
  }

  &-sm {
    line-height: $line-height-sm;
  }
}

// Links
.link {
  &--pink {
    color: $brand-pink;

    &:hover {
      color: $brand-pink;
      text-decoration: underline;
    }
  }

  &-no-decoration {
    text-decoration: none;
  }

  &-prev::before,
  &-next::after {
    display: inline-block;
    font-size: $font-size-lg;
    font-weight: bold;
  }

  &-prev::before {
    content: '\2039';
    margin-right: $size-3;
  }

  &-next::after {
    content: '\203A';
    margin-left: $size-3;
  }
}

// Disabled
.disabled {
  pointer-events: none;
}

// Opacity
.opacity {
  &-01 {
    opacity: 0.2;
  }

  &-03 {
    opacity: 0.3;
  }

  &-05 {
    opacity: 0.5;
  }

  &-07 {
    opacity: 0.7;
  }

  &-09 {
    opacity: 0.9;
  }
}

// Z-index
.z-index {
  &-1 {
    z-index: 1;
  }
}

// Rounded
.rounded {
  &-sm {
    border-radius: $border-radius-sm;
  }

  &-md {
    border-radius: $border-radius-md;
  }

  &-lg {
    border-radius: $border-radius-lg;
  }
}

// Min width

.minw {
  &-100 {
    min-width: 100%;
  }
}

// Max width
.mw {
  &-5rem {
    max-width: 5rem;
  }

  &-10rem {
    max-width: 10rem;
  }

  &-15rem {
    max-width: 15rem;
  }

  &-20rem {
    max-width: 20rem;
  }

  &-25rem {
    max-width: 25rem;
  }

  &-30rem {
    max-width: 30rem;
  }

  &-35rem {
    max-width: 35rem;
  }

  &-40rem {
    max-width: 40rem;
  }

  &-45rem {
    max-width: 45rem;
  }

  &-50rem {
    max-width: 50rem;
  }
}

// Negative spacing
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m) {
      @each $size, $length in $spacers {

        .#{$abbrev}tn#{$infix}-#{$size} {
          #{$prop}-top: -$length !important;
        }
        .#{$abbrev}rn#{$infix}-#{$size} {
          #{$prop}-right: -$length !important;
        }
        .#{$abbrev}bn#{$infix}-#{$size} {
          #{$prop}-bottom: -$length !important;
        }
        .#{$abbrev}ln#{$infix}-#{$size} {
          #{$prop}-left: -$length !important;
        }
        .#{$abbrev}xn#{$infix}-#{$size} {
          #{$prop}-left: -$length !important;
          #{$prop}-right: -$length !important;
        }
        .#{$abbrev}yn#{$infix}-#{$size} {
          #{$prop}-top: -$length !important;
          #{$prop}-bottom: -$length !important;
        }
      }
    }
  }
}

.sticky-bottom {
  @supports (position: sticky) {
    position: sticky;
    bottom: 0;
    z-index: $zindex-sticky;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.bottom-0 {
  bottom: 0;
}
