// Forms

label {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $gray-700;
}

// Boostrap has z-index for fixing some weird IE stacking bug, but for some reason
// they used value of 1, but that has issues with our select picker component
.custom-control {
  z-index: 0;
}

// Prevent Firefox to adds it's own red border to invalid inputs, which can happen even before user interaction
// see https://stackoverflow.com/questions/3809146/firefox-4-required-input-form-red-border-outline
// and also https://developer.mozilla.org/en-US/docs/Web/CSS/:invalid
input:-moz-ui-invalid {
  box-shadow: none;
}

.custom-checkbox {
  &--large {
    .custom-control-label::after,
    .custom-control-label::before {
      width: $size-8;
      height: $size-8;
    }
  }

  &--narrow {
    .custom-control-label::after,
    .custom-control-label::before {
      left: -$size-7;
    }

    &.custom-control {
      padding-left: $size-7;
    }
  }

  .custom-control-label::before {
    border-color: $gray-300;
  }

  > .form-check {
    padding-left: 0;
  }

  &--animated {
    .custom-control-input:checked ~ .custom-control-label::after {
      animation: bounce 0.4s;
      animation-iteration-count: 1;
    }
  }
}

// Workaround for problem with overflowing filename in Boostrap custom file input component
// This bug will not be fixed in Bootstrap v4 as it would introduce BC (but in v5 it's fixed).
// See https://github.com/twbs/bootstrap/issues/26933 and https://github.com/twbs/bootstrap/issues/28897
.custom-file-label {
  overflow: hidden;
  white-space: nowrap;
  padding-right: 9em;
  text-overflow: ellipsis;
}
