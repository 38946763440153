.profile-header {
  &__upsell-logo-gtm {
    text-align: center;

    &__logo-placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-color: $gray-100;
      margin-bottom: $size-5;

      @include media-breakpoint-up(md) {
        width: 80px;
        height: 80px;
      }
    }

    a {
      color: $secondary;

      &:hover {
        text-decoration: none;
        color: $primary;
      }
    }

    svg {
      width: 24px;
      height: 24px;

      @include media-breakpoint-up(md) {
        width: 42px;
        height: 42px;
      }
    }
  }
}
