// Footer

.redesigned-footer {
  padding: 64px 16px;
  font-family: sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.6;
  color: #c4c4c4;
  background-color: #262626;

  a {
    color: #ffd8a7;

    &:hover,
    &:focus {
      text-decoration: none;
      color: #e6c296;
    }
  }

  .redesigned-footer-container {
    max-width: 37.5rem;
    margin: 0 auto 40px;
  }

  .redesigned-footer-links {
    div:first-child {
      margin-bottom: 32px;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      gap: 32px;

      div:first-child {
        margin-bottom: 0;
      }

      > div {
        flex: 1;
      }
    }

    div > h2 {
      margin-bottom: 16px;
      font-size: 1rem;
      color: #fff;
    }

    div:first-child > a {
      display: inline-block;
      width: 100%;
      margin-bottom: 8px;
      color: #ffd8a7;

      &:hover,
      &:focus {
        color: #e6c296;
      }
    }

    & ul {
      list-style: none;
      padding: 0;
    }
  }

  .redesigned-footer-logo {
    width: 218px;
    height: 39px;
    margin-bottom: 24px;
  }

  strong {
    font-weight: 500;
  }
}
