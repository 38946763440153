// Brand promo

.brand-promo {
  &__logo {
    width: 7.5rem;
    margin: 0 0.25rem;

    @include media-breakpoint-up(sm) {
      width: 10rem;
      margin: 0 1.5rem;
    }
  }
}
