// Bootstrap select

$bs-select-hover-bg: $gray-200;

.bootstrap-select {
  .dropdown-item:hover {
    color: $white;
    background-color: $brand-blue-light;
  }

  .dropdown-toggle {
    position: relative;
    padding-left: $input-btn-padding-x;
    padding-right: ($input-btn-padding-x * 1.5);
    color: $link-color;
    background-color: #fff;
    border-color: $gray-300;
    border-radius: $border-radius;

    @include hover-focus-active {
      color: $link-color !important;
      background-color: $bs-select-hover-bg !important;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0.5rem;
      width: 20px;
      height: 20px;
      background-image: url(/images/common/toggle_arrow.svg);
      transform: translateY(-50%);
    }

    &:hover::before,
    &:focus::before {
      background-image: url(/images/common/toggle_arrow_active.svg);
    }

    &::after {
      color: transparent;
    }

    .filter-option {
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: $dropdown-item-padding-x;
        width: 0;
        height: 0;
        display: block;
        border: 0 solid transparent;
      }

      &-inner {
        &-inner {
          font-weight: $font-weight-normal;
          text-overflow: ellipsis;
        }
      }
    }
  }

  // Dropdown open
  &.show {
    .dropdown-toggle {
      position: relative;
      color: $link-color;
      background-color: $bs-select-hover-bg;

      &::before {
        background-image: url(/images/common/toggle_arrow_active.svg);
        transform: translateY(-50%) rotate(180deg);
      }

      .filter-option {
        &::before,
        &::after {
          border-width: 0.75rem 0.75rem 0;
        }

        &::before {
          border-top-color: $gray-300;
        }

        &::after {
          top: calc(100% - 2px);
          border-top-color: $bs-select-hover-bg;
        }
      }
    }
  }

  // Dropup
  &.dropup {

    // Dropup open
    &.show {
      .dropdown-toggle {
        &::after {
          border-top-color: $primary;
        }

        .filter-option {
          &::before,
          &::after {
            top: auto;
            bottom: 100%;
            border-width: 0 0.75rem 0.75rem;
          }

          &::before {
            border-bottom-color: $gray-300;
          }

          &::after {
            bottom: calc(100% - 2px);
            border-bottom-color: $bs-select-hover-bg;
          }
        }
      }
    }
  }
}

// Make .bs-placeholder look like normal option (not grayed out).
// See https://github.com/silviomoreto/bootstrap-select/issues/1345#issuecomment-390688201
.bootstrap-select > .dropdown-toggle.bs-placeholder {
  color: $link-color;
}
