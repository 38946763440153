// Icons

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: -0.15em;
  vertical-align: middle;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  // Align
  &--left {
    margin-right: 0.4em;
  }

  &--right {
    margin-left: 0.4em;
  }

  // Specific styles
  &--thumb-up,
  &--thumb-down {
    background-color: #fff;
    border: 1px solid currentColor;
    border-radius: 50%;
  }

  &--larger {
    width: 24px;
    height: 24px;
  }
}
