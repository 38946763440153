// Progress

$progress-height: 0.75rem;
$progress-bg: #e4e5f7;
$progress-bar-bg-from: $brand-blue-light;
$progress-bar-bg-to: $brand-pink-light;

.progress {
  padding-top: 1rem;

  &__bar {
    position: relative;
    height: $progress-height;
    background: linear-gradient(to right, $progress-bar-bg-from 0%, $progress-bar-bg-to 100%);
    border-radius: ($progress-height * 0.5);

    &--secondary {
      background: $secondary;
    }

    &-mask {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: $progress-bg;
      border-radius: 0 ($progress-height * 0.5) ($progress-height * 0.5) 0;

      @include transition($progress-bar-transition);
    }

    &[aria-valuenow="0"] .progress__value {
      transform: translateX(0%);
    }

    &[aria-valuenow="1"] .progress__value {
      transform: translateX(-20%);
    }

    &[aria-valuenow="2"] .progress__value {
      transform: translateX(-40%);
    }

    &[aria-valuenow="98"] .progress__value {
      transform: translateX(-70%);
    }

    &[aria-valuenow="99"] .progress__value {
      transform: translateX(-85%);
    }

    &[aria-valuenow="100"] .progress__value {
      transform: translateX(-100%);
    }
  }

  &__value {
    position: absolute;
    bottom: 100%;
    left: 0;
    font-size: $font-size-xs;
    color: $brand-blue;
    transform: translateX(-50%);
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    margin-top: $size-3;
    font-size: $font-size-xs;
    color: $gray-400;
  }

  &--vertical {
    width: 4rem;
    padding-top: 0;

    .progress {
      &__bar {
        height: 17rem;
        background: linear-gradient(to top, $progress-bar-bg-from 0%, $progress-bar-bg-to 100%);

        &-mask {
          border-radius: ($progress-height * 0.5) ($progress-height * 0.5) 0 0;
        }

        &[aria-valuenow="95"],
        &[aria-valuenow="96"],
        &[aria-valuenow="97"],
        &[aria-valuenow="98"],
        &[aria-valuenow="99"],
        &[aria-valuenow="100"] {
          .progress__value {
            top: 100%;
            bottom: auto;
            color: #fff;
            transform: translateX(-50%);
          }
        }
      }

      &__value {
        left: 50%;
        bottom: 0;
      }
    }
  }
}
