// Sidebar

@keyframes fadeInFromNone {
  0% {
    right: -30%;
    display: none;
    opacity: 0;
  }

  50% {
    right: -5%;
    display: block;
    opacity: 1;
  }

  100% {
    right: 0;
  }
}

.b2b-admin-sidebar {
  top: $size-7;
  height: calc(100vh);
  overflow-x: hidden;

  &__navigation {
    position: absolute;
    right: 0;
    width: 100%;
    padding-bottom: $size-10;
    animation: fadeInFromNone 0.5s ease-out;

    @include media-breakpoint-up(md) {
      position: static;
      animation: none;
    }
  }

  &--closed {
    @include media-breakpoint-down(sm) {
      height: auto;
      margin-bottom: 0;

      .b2b-admin-sidebar__navigation {
        display: none;
      }

      .b2b-admin-sidebar__hamburger {
        background: url(/images/common/hamburger.svg) no-repeat center center;
      }
    }
  }

  &__hamburger {
    background: url(/images/common/hamburger-cross.svg) no-repeat center center;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  ul {
    padding-left: 0;
    width: 100%;
  }

  &__item {
    @include list-unstyled;

    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    padding-left: $size-8;
    padding-top: $size-6;
    padding-bottom: $size-6;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    color: $gray-500;
    min-width: 55px;
    min-height: $size-9;
    border-right: 4px solid transparent;
    transition: $btn-transition;

    @include media-breakpoint-up(md) {
      min-width: 89px;
    }

    &:hover {
      border-color: $secondary;
      color: $gray-700;
      text-decoration: none;
    }

    &.active {
      color: $gray-700;
      border-color: $secondary;
      background-color: $gray-100;
    }
  }

  &__footer {
    padding-left: $size-8 !important;
    position: absolute;
    display: inline-block;
    bottom: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
      bottom: $size-10;
    }
  }
}
