@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: 'Quicksand';
  src: url(/font/Quicksand-Bold.ttf);
  font-display: block;
}

@font-face {
  font-style: normal;
  font-family: 'Inter';
  src: url(/font/Inter.ttf);
  font-display: block;
}
