// Frame

.frame {
  padding: $size-7;
  background-color: #fff;
  border: 1px solid $gray-300;
  border-radius: $border-radius-lg;

  &--shadow {
    box-shadow: $box-shadow;
  }

  @include media-breakpoint-up(md) {
    padding: $size-8 $size-9;
  }
}
