.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zindex-fixed;
  transform: translateY(0%);
  transition: $transition-base;

  &.hidden {
    transform: translateY(100%);
  }
}
