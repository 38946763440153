// Page nav

.page-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zindex-sticky;
  background-color: rgba(#fff, 0.95);
  box-shadow: 0 0 2rem rgba($brand-blue, 0.1);
  transition: transform 0.3s ease-in-out;

  &__links {
    font-size: $font-size-sm;

    &-item {
      &:not(:last-child) {
        margin-right: $size-6;
      }

      &--active {
        a {
          font-weight: $font-weight-medium;
          text-decoration: none;
          color: $brand-blue;
        }
      }
    }
  }

  &__filters {
    &-control {
      width: 100%;

      .dropdown-toggle {
        @include media-breakpoint-down(xs) {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }
      }
    }
  }

  &.hidden {
    box-shadow: none;
    transform: translateY(calc(-100% - 2rem));
  }
}
