.header-photo {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &__image {
    display: block;
    width: 100%;
    max-height: 450px;
    object-fit: cover;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg;
    }
  }

  &__upsell-header-gtm {
    position: relative;
    // percentage height of aspect ratio 21:8 (8 / 21 * 100)
    padding-top: 38.0952%;
    max-height: 350px;
    color: $secondary;
    background-color: $gray-100;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg;
    }

    &:hover {
      color: $primary;
    }

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;

        @include media-breakpoint-up(md) {
          width: 42px;
          height: 42px;
        }
      }

      span {
        margin-top: $size-5;
        font-size: $font-size-sm;

        @include media-breakpoint-up(md) {
          font-size: $font-size-md;
        }
      }
    }
  }
}
