@keyframes bounce {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }

  25% {
    transform: scale(0.25);
    opacity: 0.25;
  }

  50% {
    transform: scale(1.4);
    opacity: 1.4;
  }

  75% {
    transform: scale(0.8);
    opacity: 0.8;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
