.profile-gallery {
  &__item {
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    border-radius: $border-radius-md;
    background-color: $gray-900;

    &:hover {
      cursor: pointer;
    }
  }

  &__col {
    &:nth-child(2n-1) {
      @include media-breakpoint-up(md) {
        padding-right: $grid-gutter-width * 0.25;
      }
    }

    &:nth-child(2n) {
      @include media-breakpoint-up(md) {
        padding-left: $grid-gutter-width * 0.25;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.25s;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__description {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: $size-9 $size-6 $size-6 $size-6;
    color: $white;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $black 100%);
  }
}
