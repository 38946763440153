.tabs {
  @include list-unstyled;

  font-size: $font-size-base;
  font-weight: $font-weight-base;
  box-shadow: 0 4px 2px -2px rgba($brand-blue, 0.1);
  margin-bottom: 0;
  padding-left: 0;

  @include media-breakpoint-up(md) {
    font-size: $h4-font-size;
  }

  &__item {
    display: inline-block;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      margin-right: $size-9;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    color: $gray-500;
    display: flex;
    min-width: 55px;
    height: $size-9;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding-left: $size-5;
    padding-right: $size-5;
    border-bottom: 2px solid transparent;
    transition: $btn-transition;

    @include media-breakpoint-up(md) {
      min-width: 89px;
      padding-left: $size-6;
      padding-right: $size-6;
    }

    &:hover,
    &.active {
      color: $body-color;
      border-color: $brand-pink;
      text-decoration: none;
    }
  }

  &__badge {
    margin-left: $size-5;

    @include media-breakpoint-up(md) {
      margin-left: $size-6;
    }
  }

  &--scrollable {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &--disable-shadow {
    box-shadow: none;
  }

  &__arrow {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: $size-9;
    padding: 0;
    border: none;
    color: $gray-500;
    background-color: transparent;

    &--left {
      left: 0;

      svg {
        transform: rotate(180deg);
      }
    }

    &--right {
      right: 0;
    }

    &:focus,
    &:hover {
      color: $brand-pink;
    }
  }

  &__arrow-icon {
    width: 20px;
    height: 20px;
    margin-top: 0;
  }
}
