.question-slider {
  position: relative;

  &__item {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    transition: left 0.3s ease;

    &.active,
    &--prev,
    &--next {
      display: block;
      left: 0;
    }

    &--left.active,
    &--prev {
      left: -100vw;
    }

    &--right.active,
    &--next {
      left: 100vw;
    }
  }
}
