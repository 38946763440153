// Intro

.intro {
  &__heading-with-logo {
    display: flex;
    align-items: center;
    margin-bottom: $size-6;
  }

  &__company-info {
    margin-bottom: $size-7;
  }

  &__title {
    margin-bottom: 0;

    @include media-breakpoint-down(xs) {
      font-size: $font-size-xl;
    }

    &--small {
      font-size: $font-size-lg;
    }
  }

  &__subtitle {
    @include media-breakpoint-up(sm) {
      font-size: $font-size-md;
    }
  }

  &__company-logo {
    margin-left: $size-5;
  }
}
