// Typography

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  //strong {
  //  font-weight: inherit;
  //}

  // Heading inside of a link should have the underline color as normal heading (not link).
  // In order to achieve this, we decorate the heading too when it is inside a link.
  a:not(.text-decoration-none) & {
    text-decoration: $link-decoration;
  }

  a:hover:not(.text-decoration-none) & {
    text-decoration: $link-hover-decoration;
  }

  // Link inside of a heading should have the underline color as heading (not link).
  & a {
    color: $headings-color;

    @include hover() {
      color: $headings-color;
    }
  }
}

h1,
.h1 {
  //color: $brand-blue;
  //text-transform: uppercase;
}

h2,
.h2 {
  //color: $brand-blue;
  //text-transform: uppercase;
}

h3,
.h3 {
  //color: $brand-blue;
  //text-transform: uppercase;
}

h4,
.h4 {
  //color: $brand-blue;
  //text-transform: uppercase;
}

h5,
.h5 {
  font-family: $font-family-sans-serif;
  text-transform: none;
  color: $body-color;
}

h6,
.h6 {
  font-family: $font-family-sans-serif;
  text-transform: none;
  color: $body-color;
}

.heavy-text {
  line-height: $line-height-heavy;
  font-size: $font-size-base;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-md;
  }
}
