// Layout

.container {
  width: 100%;
  max-width: 960px;

  &--lg {
    max-width: 1160px;
  }
}

.body-container {
  &--disable-scroll {
    @include media-breakpoint-down(md) {
      height: 100vh;
      overflow: hidden;
    }
  }
}
