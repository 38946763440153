.call-to-action-panel {
  text-align: left;

  @include media-breakpoint-up(sm) {
    text-align: center;
  }

  &__text {
    font-size: $font-size-sm;

    @include media-breakpoint-up(sm) {
      max-width: 60ch;
      margin-left: auto;
      margin-right: auto;
    }

    margin-bottom: 0;
  }

  &__button {
    margin-top: $size-7;
  }

  &__icon {
    margin-right: $size-5;
  }
}
